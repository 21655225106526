/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormGroup,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useMutation } from "@tanstack/react-query";
import {
  getUtilityListQuery,
  getIsoListQuery,
  getGasUtilitiesListQuery,
  getSiteSettingsMutation,
  deleteOrganizationMutation,
  postSiteSettingsMutation,
} from "../../lib/Queries";
import Loading from "../Shared/Loading";
import countries from "../../lib/Countries";
import siteData from "../../lib/siteData";
import DataComponentError from "../Shared/DataComponentError";
import { LoadingButton } from "@mui/lab";
import timezones from "../../lib/Timezones";
import states from "../../lib/States";
import PropTypes from "prop-types";
import DeleteSiteModal from "./SiteDeleteModal";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";

const styles = {
  caption: {
    color: "rgba(0, 0, 0, 0.60)",
    marginBottom: "1rem",
  },
  settingsPanel: {
    width: "100%",
    py: "3rem",
    px: "4rem",
  },
  settingsNewPanel: {
    width: "100%",
    py: "3rem",
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "700",
  },
  genericTab: {
    backgroundColor: "#fff",
    border: "1px solid #E1E2E4",
    borderRadius: "5px",
    padding: "2rem",
    width: "184px",
    height: "151px",
    justifyContent: "center",
    fontSize: 12,
  },
  genericTabLabel: {
    fontSize: 14,
  },
};

const SiteSettings = ({ OrganizationUuid, site, onClose }) => {
  const {
    statusList,
    contractTypesList,
    dealStructuresList,
    billSavingsProvidersList,
    gridServicesProvidersList,
  } = siteData;
  const theme = useTheme();
  const [dirty, setDirty] = useState(false);
  const [name, setName] = useState(site?.name || null);
  const [siteState, setSiteState] = useState(site?.state || null);
  const [iso, setIso] = useState(site?.iso || null);
  const [gasUtility, setGasUtility] = useState(site?.gas_utility || null);
  const [utility, setUtility] = useState(site?.utility || null);
  const [timezone, setTimezone] = useState(site?.timezone || null);
  const [address1, setAddress1] = useState(site?.address?.address1 || null);
  const [city, setCity] = useState(site?.address?.city || null);
  const [zip, setZip] = useState(site?.address?.zip || null);
  const [usState, setUsState] = useState(site?.address?.state || null);
  const [country, setCountry] = useState(site?.address?.country || "United States");
  const [latitude, setLatitude] = useState(site?.latitude || null);
  const [longitude, setLongitude] = useState(site?.longitude || null);
  const [siteNotifications, setSiteNotifications] = useState(
    site?.send_notifications || site?.uuid === undefined,
  );
  const [contractType, setContractType] = useState(site?.contract_type || []);
  const [dealStructure, setDealStructure] = useState(site?.deal_structure || []);
  const [billSavingsProvider, setBillSavingsProvider] = useState(
    site?.bill_savings_provider || null,
  );
  const [gridServicesProvider, setGridServicesProvider] = useState(
    site?.grid_services_provider || null,
  );
  const [commissionedDate, setCommissionedDate] = useState(
    site?.commissioned_date ? new dayjs(site.commissioned_date) : null,
  );
  const [contractEndDate, setContractEndDate] = useState(
    site?.contract_end_date ? new dayjs(site.contract_end_date) : null,
  );
  const [dateAcquired, setDateAcquired] = useState(
    site?.date_acquired ? new dayjs(site.date_acquired) : null,
  );
  const [open, setOpen] = useState(false); // State to control modal visibility
  const isLoading = false;
  const error = false;
  const {
    data: isoList,
    isLoading: isoListIsLoading,
    refetch: refetchIsoList,
  } = useQuery({
    ...getIsoListQuery(),
  });
  const {
    data: gasUtilitiesList,
    isLoading: gasUtilitiesListIsLoading,
    refetch: refetchGasUtilitiesList,
  } = useQuery({
    ...getGasUtilitiesListQuery(),
  });
  const {
    data: utilityList,
    isLoading: utilityListIsLoading,
    refetch: refetchUtilityList,
  } = useQuery({
    ...getUtilityListQuery(false),
  });
  const mutation = useMutation({
    ...getSiteSettingsMutation(),
  });

  const mutation_delete = useMutation({
    ...deleteOrganizationMutation(),
  });

  const mutation_new_site = useMutation({
    ...postSiteSettingsMutation(),
  });

  const handleSaveAndClose = () => {
    const newSettings = {
      uuid: site?.uuid,
      name: name,
      state: siteState,
      latitude: latitude,
      longitude: longitude,
      timezone: timezone,
      iso: iso,
      gas_utility: gasUtility,
      Utility: utility,
      send_notifications: siteNotifications,
      organization_uuid: OrganizationUuid,
      address: {
        address1: address1,
        city: city,
        state: usState,
        zip: zip,
        country: country,
      },
      contract_type: contractType,
      deal_structure: dealStructure,
      bill_savings_provider: billSavingsProvider,
      grid_services_provider: gridServicesProvider,
      commissioned_date: commissionedDate,
      contract_end_date: contractEndDate,
      date_acquired: dateAcquired,
    };

    if (site?.uuid) {
      mutation.mutate(newSettings, {
        onSuccess: () => {
          setDirty(false);
        },
      });
    } else {
      mutation_new_site.mutate(newSettings, {
        onSuccess: () => {
          setDirty(false);
          onClose();
        },
      });
    }
  };

  const allFieldsFilled = () => {
    return name && address1 && city && siteState && usState && zip && timezone && utility && iso;
  };

  const timezonesOptions = timezones.map((zone) => ({
    label: zone,
    value: zone,
  }));

  const statesOptions = Object.keys(states).map((stateAbbreviation) => ({
    label: stateAbbreviation,
    value: stateAbbreviation,
  }));

  const getCountries = () => {
    return countries.map((country) => (
      <MenuItem key={country} value={country}>
        {country}
      </MenuItem>
    ));
  };

  const getStatus = () => {
    return statusList.map((status) => (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    ));
  };
  const getContractTypes = () => {
    return contractTypesList.map((contract) => (
      <MenuItem key={contract} value={contract}>
        {contract}
      </MenuItem>
    ));
  };
  const getDealStructures = () => {
    return dealStructuresList.map((deal) => (
      <MenuItem key={deal} value={deal}>
        {deal}
      </MenuItem>
    ));
  };
  const getbillSavingsProviders = () => {
    return billSavingsProvidersList.map((provider) => (
      <MenuItem key={provider} value={provider}>
        {provider}
      </MenuItem>
    ));
  };
  const getServicesProviders = () => {
    return gridServicesProvidersList.map((provider) => (
      <MenuItem key={provider} value={provider}>
        {provider}
      </MenuItem>
    ));
  };

  const handleDelete = () => {
    setOpen(true); // Open the confirmation modal
  };

  const confirmDelete = () => {
    mutation_delete.mutate(site?.uuid, {
      onSuccess: () => {
        console.log("Site deleted successfully");
        setOpen(false); // Close the modal on success
      },
      onError: (error) => {
        console.error("Error deleting site:", error);
        setOpen(false); // Close the modal on error
      },
    });
  };

  return (
    <Box>
      <Box sx={site?.uuid && theme.classes.genericPanel}>
        {site?.uuid ? (
          <Typography variant="subtitle2" sx={styles.subtitle}>
            Site Details
          </Typography>
        ) : (
          <Typography
            sx={{
              mb: "1.5rem",
              mt: "-1.5rem",
              fontSize: "34px",
              fontWeight: 850,
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            New Site
          </Typography>
        )}
        <Box
          display="flex"
          flexDirection="column"
          sx={site?.uuid ? styles.settingsPanel : { ...styles.settingsNewPanel }}
        >
          <FormGroup fullWidth variant="outlined" margin="normal">
            <Box mb="24px">
              <TextField
                id="name"
                name="name"
                label="Site Name"
                size="small"
                value={name}
                disabled={!!site?.uuid}
                variant="outlined"
                onChange={(ev) => {
                  setName(ev.target.value);
                  setDirty(true);
                }}
                sx={{ width: "24.5rem", fontSize: 16, fontWeight: 400 }}
                InputProps={{
                  sx: { fontSize: 16, fontWeight: 400 },
                }}
              />
            </Box>
            <Box>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <TextField
                  id="address"
                  name="address"
                  label="Site Address"
                  required
                  value={address1}
                  size="small"
                  variant="outlined"
                  onChange={(ev) => {
                    setAddress1(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "24.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  InputProps={{
                    sx: { fontSize: 16, fontWeight: 400 },
                  }}
                />
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  required
                  value={city}
                  size="small"
                  variant="outlined"
                  onChange={(ev) => {
                    setCity(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  InputProps={{
                    sx: { fontSize: 16, fontWeight: 400 },
                  }}
                />
                <FormControl sx={{ width: "5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="usState"
                    name="usState"
                    options={statesOptions}
                    size="small"
                    getOptionLabel={(option) => option.label}
                    value={statesOptions.find((option) => option.value === usState) || null}
                    onChange={(event, newValue) => {
                      setUsState(newValue ? newValue.value : "");
                      setDirty(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="State" required variant="outlined" />
                    )}
                    key={(option) => option.value}
                    disableClearable
                  />
                </FormControl>
                <TextField
                  id="zip"
                  name="zip"
                  label="ZIP"
                  required
                  value={zip}
                  size="small"
                  variant="outlined"
                  onChange={(ev) => {
                    setZip(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "5rem",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  InputProps={{
                    sx: { fontSize: 16, fontWeight: 400 },
                  }}
                />
              </Box>

              <Box display="flex" mb="4.5rem" alignItems="center">
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="country" required>
                    Country
                  </InputLabel>
                  <Select
                    id="country"
                    name="country"
                    label="Country"
                    disabled
                    required
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={country}
                    variant="outlined"
                    onChange={(ev) => {
                      setCountry(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getCountries()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="timezone"
                    name="timezone"
                    options={timezonesOptions}
                    getOptionLabel={(option) => option.label}
                    value={timezonesOptions.find((option) => option.value === timezone) || null}
                    onChange={(event, newValue) => {
                      setTimezone(newValue ? newValue.value : "");
                      setDirty(true);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Time Zone" required variant="outlined" />
                    )}
                    key={(option) => option.value}
                  />
                </FormControl>
                <TextField
                  id="latitude"
                  name="latitude"
                  label="Latitude"
                  value={latitude}
                  size="small"
                  variant="outlined"
                  onChange={(ev) => {
                    setLatitude(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  error={
                    (isNaN(latitude) || latitude < -90 || latitude > 90) && latitude?.length > 0
                  }
                  helperText={
                    (isNaN(latitude) || latitude < -90 || latitude > 90) && latitude.length > 0
                      ? "Latitude must be a number between -90 and 90"
                      : ""
                  }
                />
                <TextField
                  id="longitude"
                  name="longitude"
                  size="small"
                  label="Longitude"
                  value={longitude}
                  variant="outlined"
                  onChange={(ev) => {
                    setLongitude(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  error={
                    (isNaN(longitude) || longitude < -180 || longitude > 180) &&
                    longitude?.length > 0
                  }
                  helperText={
                    (isNaN(longitude) || longitude < -180 || longitude > 180) &&
                    longitude.length > 0
                      ? "Longitude must be a number between -180 and 180"
                      : ""
                  }
                />
              </Box>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <FormControl sx={{ width: "24.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="utility"
                    options={utilityList?.utilities || []}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    value={utilityList?.utilities.find((item) => item.uuid === utility) || null}
                    onChange={(event, newValue) => {
                      setUtility(newValue ? newValue.uuid : "");
                      setDirty(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Electric Utility" variant="outlined" required />
                    )}
                    key={(option) => option.uuid}
                  />
                </FormControl>
                <FormControl sx={{ width: "24.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="gasUtility"
                    options={gasUtilitiesList || []}
                    loading={gasUtilitiesListIsLoading}
                    getOptionLabel={(option) => option.Company}
                    value={gasUtilitiesList?.find((item) => item.UUID === gasUtility) || null}
                    size="small"
                    onChange={(event, newValue) => {
                      setGasUtility(newValue ? newValue.UUID : "");
                      setDirty(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Gas Utility" variant="outlined" />
                    )}
                    key={(option) => option.UUID}
                  />
                </FormControl>
              </Box>
              <Box display="flex" mb="4.5rem" alignItems="center">
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="iso"
                    options={isoList?.iso || []}
                    getOptionLabel={(option) => option.name}
                    value={isoList?.iso.find((item) => item.uuid === iso) || null}
                    size="small"
                    onChange={(event, newValue) => {
                      setIso(newValue ? newValue.uuid : "");
                      setDirty(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="ISO" variant="outlined" required />
                    )}
                    key={(option) => option.uuid}
                  />
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="siteState" required>
                    Status
                  </InputLabel>
                  <Select
                    id="siteState"
                    name="siteState"
                    label="Status"
                    size="small"
                    required
                    value={siteState}
                    variant="outlined"
                    onChange={(ev) => {
                      setSiteState(ev.target.value);
                      setDirty(true);
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                  >
                    {getStatus()}
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="large"
                      checked={siteNotifications}
                      onChange={(ev, checked) => {
                        setSiteNotifications(checked);
                        setDirty(true);
                      }}
                      disabled={!site?.uuid}
                    />
                  }
                  label="Enable Site Notifications for Scale Users"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                />
              </Box>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="Contract Type">Contract Type</InputLabel>
                  <Select
                    id="contractType"
                    name="contractType"
                    multiple
                    label="Contract Type"
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={contractType}
                    variant="outlined"
                    onChange={(ev) => {
                      setContractType(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getContractTypes()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="Deal Structure">Deal Structure</InputLabel>
                  <Select
                    id="dealStructure"
                    name="dealStructure"
                    label="Deal Structure"
                    multiple
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={dealStructure}
                    variant="outlined"
                    onChange={(ev) => {
                      setDealStructure(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getDealStructures()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="Bill Savings Provider">Bill Savings Provider</InputLabel>
                  <Select
                    id="billSavingsProvider"
                    name="billSavingsProvider"
                    label="Bill Savings Provider"
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={billSavingsProvider}
                    variant="outlined"
                    onChange={(ev) => {
                      setBillSavingsProvider(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getbillSavingsProviders()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="Grid Services Provider">Grid Services Provider</InputLabel>
                  <Select
                    id="gridServiceProvider"
                    name="gridServiceProvider"
                    label="Grid Services Provider"
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={gridServicesProvider}
                    variant="outlined"
                    onChange={(ev) => {
                      setGridServicesProvider(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getServicesProviders()}
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Commercial Operating Date"
                      value={commissionedDate}
                      onChange={(newValue) => {
                        setCommissionedDate(newValue);
                        setDirty(true);
                      }}
                      slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon,
                      }}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          size: "small",
                        },
                        openPickerIcon: {
                          fontSize: "20rem",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Contract End Date"
                      value={contractEndDate}
                      onChange={(newValue) => {
                        setContractEndDate(newValue);
                        setDirty(true);
                      }}
                      slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon,
                      }}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          size: "small",
                        },
                        openPickerIcon: {
                          fontSize: "20rem",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Acquired (If Applicable)"
                      value={dateAcquired}
                      onChange={(newValue) => {
                        setDateAcquired(newValue);
                        setDirty(true);
                      }}
                      slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon,
                      }}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          size: "small",
                        },
                        openPickerIcon: {
                          fontSize: "20rem",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
            </Box>
          </FormGroup>

          {isLoading ? <Loading /> : null}
          {error ? <DataComponentError /> : null}
        </Box>
        {!site?.uuid && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              mt: "-2rem",
            }}
          >
            By default, all Scale users have access to new sites, and other users have no access.
            <br />
            User notifications default to On once a user has access.
          </Typography>
        )}
      </Box>
      <Box
        pt="2rem"
        pb="-1.5rem"
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        {site?.uuid ? (
          <Button
            sx={{
              width: "150px",
            }}
            variant="outlined"
            onClick={handleDelete}
            color="error"
          >
            Delete Site...
          </Button>
        ) : (
          <Box flexGrow={1} />
        )}
        <Box>
          {!site?.uuid && (
            <Button
              variant="outlined"
              sx={{
                marginLeft: "1em",
                width: "150px",
                color: "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.87)",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            loading={mutation?.isLoading || mutation_new_site?.isLoading}
            onClick={dirty ? handleSaveAndClose : () => {}}
            variant="contained"
            sx={{ marginLeft: "1em", width: "150px" }}
            disabled={!dirty || (!allFieldsFilled() && !mutation?.isSuccess)}
            color={mutation?.isSuccess && !dirty ? "success" : "primary"}
          >
            {mutation.isSuccess && !dirty ? "Saved" : site?.uuid ? "Save Changes" : "Create Site"}
          </LoadingButton>
          <DeleteSiteModal
            open={open}
            onClose={() => setOpen(false)}
            onConfirm={confirmDelete}
            siteName={site?.name}
          />
        </Box>
      </Box>
    </Box>
  );
};

SiteSettings.propTypes = {
  OrganizationUuid: PropTypes.string,
  site: PropTypes.object,
  onClose: PropTypes.func,
};

export default SiteSettings;
