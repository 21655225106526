import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { SiteFilterOptions, getFilteredOptions, getFilteredSites } from "../../lib/SiteDataUtil";
import TextField from "@mui/material/TextField";
import { SitePowerProduction } from "../../lib/Types";
import { useState } from "react";
import { cloneDeep } from "lodash";

const defaultSiteFilter: SiteFilterOptions = {
  customer: new Set(),
  iso: new Set(),
  state: new Set(),
  operatingState: new Set(["Operating"]),
  assets: new Set(),
};

interface Props {
  sites: SitePowerProduction[];
  setFilteredSitesUuids: Function;
}

export default function DashboardFilter({ sites, setFilteredSitesUuids }: Props) {
  const [appliedFilter, setAppliedFilter] = useState<SiteFilterOptions>(defaultSiteFilter);

  useEffect(() => {
    const filteredSites = getFilteredSites(sites, appliedFilter);
    const filteredSiteUuids = filteredSites.map((site) => site.uuid);
    setFilteredSitesUuids(filteredSiteUuids);
  }, [appliedFilter, sites]);

  if (!sites) return null;

  const fieldDisplayMapping = [
    { key: "customer", displayName: "Organization" },
    { key: "iso", displayName: "ISO" },
    { key: "state", displayName: "State" },
    { key: "assets", displayName: "Asset" },
    { key: "operatingState", displayName: "Status" },
  ];

  const getHandleSelectorChange = (field: string) => {
    // wrap handleSelectorChange so a unique field can be used
    const handleSelectorChange = (event: any, value: any) => {
      let newFilter = cloneDeep(appliedFilter);
      newFilter[field as keyof SiteFilterOptions] = new Set(value);
      setAppliedFilter(newFilter);
    };
    return handleSelectorChange;
  };

  const getSelector = (field: string) => {
    const optionsSet = getFilteredOptions(sites, appliedFilter, field);
    const options = Array.from(optionsSet);
    const optionsSorted = options.sort(function (a, b) {
      return a.localeCompare(b);
    });
    const fieldDisplayName = fieldDisplayMapping.find(
      (mapping) => mapping.key === field,
    )?.displayName;
    const appliedValues = Array.from(appliedFilter[field as keyof SiteFilterOptions]);
    const flexWidth = ["state", "assets"].includes(field) ? 0.75 : 1;
    return (
      <Box key={field} sx={{ flex: flexWidth }}>
        <Autocomplete
          multiple
          id="tags-readOnly"
          options={optionsSorted}
          value={appliedValues}
          onChange={getHandleSelectorChange(field)}
          renderInput={(params) => <TextField {...params} label={fieldDisplayName} />}
          size="small"
          limitTags={1}
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "white",
            },
            "& .MuiAutocomplete-input": {
              minWidth: "10px !important",
            },
          }}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1, marginTop: ".8rem" }}>
      {fieldDisplayMapping.map((field) => {
        const key = field.key as keyof SiteFilterOptions;
        return getSelector(key);
      })}
    </Box>
  );
}
