/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import * as React from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { getUtilityListQuery, getIsoListQuery, getGasUtilitiesListQuery } from "../../lib/Queries";
import { useQuery } from "@tanstack/react-query";

const SiteSpecs = ({ site }) => {
  const theme = useTheme();

  const { data: isoList, isLoading: isIsoListLoading } = useQuery({
    ...getIsoListQuery(),
  });
  const { data: gasUtilitiesList, isLoading: isGasUtilitiesListLoading } = useQuery({
    ...getGasUtilitiesListQuery(),
  });
  const { data: utilityList, isLoading: isUtilitiesListLoading } = useQuery({
    ...getUtilityListQuery(false),
  });

  const isLoading = isIsoListLoading || isGasUtilitiesListLoading || isUtilitiesListLoading;

  const getValue = (value) => {
    if (!value || value === "NONE") return "-";
    return `${value} `;
  };
  const getArrayValue = (value) => {
    if (value) {
      // Join array values with commas, and add a space after each comma
      return value.join(", ");
    }
    return "-";
  };
  const getDateValue = (date) => {
    if (!date) return "-";
    return dayjs(date).format("MMM D YYYY");
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" sx={theme.classes.genericPanel}>
      {/* Title */}
      <Box mb={4}>
        <Typography variant="subtitle2" color="secondary">
          Site Specs
        </Typography>
      </Box>
      <Box mb={6} px={8}>
        <Box mb={2}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Connections
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Electric Utility"
                value={getValue(
                  utilityList?.utilities.find((item) => item.uuid === site?.Utility)?.name || null,
                )}
                variant="standard"
                size="small"
                multiline
                minRows={1}
                maxRows={4}
                InputProps={{
                  readOnly: true, // Makes it read-only
                  disableUnderline: true, // Removes underline
                }}
                InputLabelProps={{
                  shrink: true, // Keeps label at the top
                  focused: false, // Prevent label highlight
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Gas Utility"
                value={getValue(
                  gasUtilitiesList.find((item) => item.UUID === site?.gas_utility)?.Company || null,
                )}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="ISO / RTO"
                value={getValue(isoList?.iso.find((item) => item.uuid === site?.iso)?.name || null)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Commercial Operating Date"
                value={getDateValue(site?.commissioned_date)}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mb={4} px={8}>
        <Box mb={2}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Agreements
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Contract Type"
                value={getArrayValue(site?.contract_type)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Bill Savings Provider"
                value={getValue(site?.bill_savings_provider)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Grid Services Provider"
                value={getValue(site?.grid_services_provider)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Contract End Date"
                value={getDateValue(site?.contract_end_date)}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Deal Structure"
                value={getArrayValue(site?.deal_structure)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}></Grid>
            <Grid item xs={6} sm={3}></Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Acquired Date"
                value={getDateValue(site?.date_acquired)}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

SiteSpecs.propTypes = {
  site: PropTypes.object,
};

export default SiteSpecs;
