/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { formatDateString, keys } from "../Logic/formatUtils";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import AlarmTooltipContents from "./AlarmTooltipContents";

/** CustomTooltip component renders the custom tooltip content for the Recharts AreaChart.*/
const LineGraphTooltip = ({
  active,
  getName,
  getUnit,
  areaVisibility,
  getBucketAlarms,
  timezone,
  showAlarms,
  metricUuids,
  isStaticLegend = false,
  graphPayload,
  sortMetrics = true,
  colorPaletteKey,
}) => {
  const theme = useTheme();

  const styles = {
    toolTipItem: {
      display: "flex",
      alignItems: "center",
    },
  };

  const showTooltipInsideGraph = active && graphPayload?.length;
  const showStaticLegendValues = isStaticLegend && graphPayload?.length;
  const showStaticLegendNoValues = isStaticLegend && metricUuids?.length && !graphPayload?.length;

  if (!(showTooltipInsideGraph || showStaticLegendValues || showStaticLegendNoValues)) {
    return null;
  }

  const getSortedPayload = () => {
    return graphPayload.sort((a, b) => {
      return keys.indexOf(a.name) - keys.indexOf(b.name);
    });
  };

  const getFormattedName = (entry) => {
    return entry.name.charAt(0).toUpperCase() + entry.name.replace(/_/g, " ").slice(1);
  };

  const getTooltipHoverData = () => {
    const payload = sortMetrics ? graphPayload : getSortedPayload();

    const tooltipData = payload.map((item, index) => {
      const uuid = item.name;
      const unit = getUnit ? getUnit(uuid) : "kW";
      const name = getName ? getName(uuid) : getFormattedName(item);
      const shouldDisplayItem = !areaVisibility || areaVisibility[uuid];
      if (!shouldDisplayItem) return null;
      const hasDefinedValue = item.value !== null && item.value !== undefined;
      const value = hasDefinedValue ? ` ${parseFloat(item.value.toFixed(1))} ${unit}` : " --";
      const color = metricUuids?.length ? getColor(uuid, metricUuids) : item.color;
      return {
        name,
        value,
        unit,
        color,
        index,
      };
    });

    return tooltipData.filter((item) => item != null);
  };

  /** Get color based on uuid/name order in metrics list for static legend*/
  const getColor = (uuid, metricUuids) => {
    let colorIndex = null;
    for (const [index, value] of Object.entries(metricUuids)) {
      if (value === uuid) {
        colorIndex = index;
      }
    }
    return theme.recharts[colorPaletteKey][colorIndex];
  };

  const getSortedMetricsNames = () => {
    const metricsUuids = metricUuids.map((uuid) => ({ name: getName(uuid), uuid: uuid }));
    if (!sortMetrics) {
      return metricsUuids;
    }
    return metricsUuids.sort((a, b) => {
      return keys.indexOf(a.name) - keys.indexOf(b.name);
    });
  };

  const getStaticLegendData = () => {
    const staticLegendData = getSortedMetricsNames().map((item, index) => {
      const unit = getUnit ? getUnit(item.uuid) : "kW";
      const name = item.name;
      const value = " " + unit;
      const color = getColor(item.uuid, metricUuids);
      return {
        name,
        value,
        unit,
        color,
        index,
      };
    });

    return staticLegendData.filter((item) => item != null);
  };

  const getRenderData = () => {
    const renderData = graphPayload?.length ? getTooltipHoverData() : getStaticLegendData();
    return renderData;
  };

  const alarms = graphPayload?.length ? getBucketAlarms(graphPayload) : null;

  return (
    <Box display="flex" flexDirection="column">
      <Box sx={theme.classes.customTooltipContainer}>
        {graphPayload && (
          <p className="tooltip-label">{`${formatDateString(graphPayload[0].payload.bucket)}`}</p>
        )}

        {getRenderData().map((item) => (
          <Box sx={styles.toolTipItem} key={item.index}>
            <span className={`tooltip-color`} style={{ backgroundColor: item.color }}></span>
            <span className="tooltip-type">{item.name}: </span>
            <span className="tooltip-value">{item.value}</span>
            <br />
          </Box>
        ))}
      </Box>
      {showAlarms && graphPayload && <AlarmTooltipContents alarms={alarms} timezone={timezone} />}
    </Box>
  );
};

LineGraphTooltip.propTypes = {
  active: PropTypes.bool,
  getUnit: PropTypes.func,
  getName: PropTypes.func,
  areaVisibility: PropTypes.object,
  getBucketAlarms: PropTypes.func,
  timezone: PropTypes.string,
  showAlarms: PropTypes.bool,
  metricUuids: PropTypes.array,
  isStaticLegend: PropTypes.bool,
  graphPayload: PropTypes.array,
  sortMetrics: PropTypes.bool,
  colorPaletteKey: PropTypes.array,
};

export default LineGraphTooltip;
