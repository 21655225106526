/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import {
  getPortfolioPowerQuery,
  getAlarmDetailQuery,
  getSiteMetadataQuery,
} from "../../lib/Queries";
import AlertBox from "../Shared/AlertBox";
import SitePowerGraph from "../Graphs/Chart/SitePowerGraph";
import SiteDiagram from "../Graphs/Diagram/SiteDiagram";
import Loading from "../Shared/Loading";
import DataComponentError from "../Shared/DataComponentError";
import SiteWeather from "../Dashboard/SiteWeather";
import SiteHeader from "./SiteHeader";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import SiteEquipmentDetail from "./SiteEquipmentDetail";
import SiteSpecs from "./SiteSpecs";
import GraphFavoriteList from "../Graphs/Common/GraphFavoriteList";
import TimeSeriesLineGraphModal from "../Graphs/Common/TimeSeriesLineGraphModal";

const Site = ({ flags }) => {
  const [alertData, setAlertData] = useState(null);
  const [activeTab, setActiveTab] = useState("summary");
  const { sitename } = useParams();
  const [searchParams] = useSearchParams();
  const [displaySite, setDisplaySite] = useState(null);
  const [siteValid, setSiteValid] = useState(true);
  const alarmUuid = searchParams.get("alarm");
  const [graphFavoritesIsOpen, setGraphFavoritesIsOpen] = useState(false);
  const [selectedGraphFavorite, setSelectedGraphFavorite] = useState(null);

  const theme = useTheme();
  console.log("theme", theme.palette.primary);

  const InvalidSite = () => (
    <div className="chart-graph">
      <Typography
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        variant="h5"
      >
        This site is not yet operational
      </Typography>
    </div>
  );

  const {
    isLoading: isMetadataLoading,
    error: metadataError,
    data: siteMetadata,
  } = useQuery({
    ...getSiteMetadataQuery(displaySite?.uuid),
    enabled: Boolean(displaySite?.uuid),
  });

  const { data: siteData } = useQuery(getPortfolioPowerQuery());

  const { error: alarmDetailError, data: alarmDetailData } = useQuery({
    ...getAlarmDetailQuery(displaySite?.uuid, alarmUuid),
    enabled: Boolean(displaySite?.uuid && alarmUuid),
  });

  useEffect(() => {
    if (siteData?.sites?.length > 0) {
      let sites = siteData.sites;
      for (const site of sites) {
        let test1 = site.name.replaceAll("-", " ");
        let test2 = sitename.replaceAll("-", " ");
        if (test1 === test2) {
          localStorage.setItem("site", site.uuid);
          setDisplaySite(site);
          break;
        }
      }
    }
  }, [siteData]);

  useEffect(() => {
    if (siteMetadata) {
      setSiteValid(
        siteMetadata.state === "commissioned" ||
          siteMetadata.state === "Operating" ||
          siteMetadata.state === "Retired" ||
          siteMetadata.state === "Commissioning",
      );
    }
  }, [siteMetadata]);

  const getAlarmDetailErrorMessage = () => {
    let message = "There was an error retrieving the requested alarm";
    if (alarmDetailError?.response?.status === 404) {
      message = "The requested alarm was not found";
    }
    if (alarmDetailData) {
      if (alarmDetailData.alarms.length > 0) {
        let alarm = alarmDetailData.alarms[0];
        if (alarm.stop_time) {
          message = `The alarm, ${alarm.name} for ${alarm.type} occurring at ${alarm.date} is no longer active!`;
        }
      }
    }
    return message;
  };

  const showAlarmDetailErrorMessage = () => {
    if (alarmDetailError) {
      return true;
    }
    if (alarmDetailData?.alarms?.length > 0) {
      if (alarmDetailData.alarms[0].stop_time) {
        return true;
      }
    }
    return false;
  };

  const handleShowGraphFavorites = (graphFavorite) => {
    setSelectedGraphFavorite(graphFavorite);
    setGraphFavoritesIsOpen(true);
  };

  const handleCloseGraphFavorites = () => {
    setGraphFavoritesIsOpen(false);
    setSelectedGraphFavorite(null);
  };

  const getContent = () => {
    let content;
    if (metadataError) {
      content = (
        <div className="chart-graph">
          <DataComponentError />
        </div>
      );
    } else if (isMetadataLoading) {
      content = (
        <div className="chart-graph">
          <Loading />
        </div>
      );
    } else {
      content = (
        <>
          {activeTab === "summary" ? (
            <>
              {siteValid ? (
                <>
                  <SiteDiagram site={siteMetadata} />
                  <div className="spacer"></div>
                  <SitePowerGraph site={siteMetadata} />
                  <GraphFavoriteList
                    site={siteMetadata}
                    handleShowGraphFavorites={handleShowGraphFavorites}
                    graphFavoritesIsOpen={graphFavoritesIsOpen}
                  />
                  <TimeSeriesLineGraphModal
                    isOpen={graphFavoritesIsOpen}
                    setIsOpen={setGraphFavoritesIsOpen}
                    onClose={handleCloseGraphFavorites}
                    graphFavorite={selectedGraphFavorite}
                    site={siteMetadata}
                  />
                </>
              ) : (
                <InvalidSite />
              )}
            </>
          ) : null}

          {activeTab === "detail" ? (
            <>{siteValid ? <SiteEquipmentDetail site={siteMetadata} /> : <InvalidSite />}</>
          ) : null}
          {activeTab === "specs" ? (
            <>{siteValid ? <SiteSpecs site={siteMetadata} /> : <InvalidSite />}</>
          ) : null}
        </>
      );
    }
    return content;
  };

  return (
    <div className="table-responsive">
      <div className="container">
        <div className="table-div">
          {showAlarmDetailErrorMessage() ? (
            <Box pb="2rem">
              <AlertBox
                type={"success"}
                message={getAlarmDetailErrorMessage()}
                openAlert={true}
                clearAlert={() => setAlertData(null)}
              />
            </Box>
          ) : null}
          <SiteHeader metadata={siteMetadata} setActive={setActiveTab} />
          {flags.siteWeather ? (
            <Box mb="10px">
              <SiteWeather />
            </Box>
          ) : null}
          <div className="spacer"></div>

          {getContent()}
        </div>
      </div>
    </div>
  );
};

Site.propTypes = {
  flags: PropTypes.object,
};

export default withLDConsumer()(Site);
