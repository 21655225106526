/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import CollapsibleTable from "./CollapsibleTable";
import Loading from "../Shared/Loading";
import DataComponentError from "../Shared/DataComponentError";
import { keys } from "../Graphs/Logic/formatUtils";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { getPortfolioPowerQuery } from "../../lib/Queries";
import useUserStore from "../../state/UserStore";
import DashboardFilter from "./DashboardFilter";

const Dashboard = ({ flags }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userStore = useUserStore((state) => state);
  const [filteredSitesUuids, setFilteredSitesUuids] = React.useState([]);

  const { isLoading, error, data } = useQuery({
    ...getPortfolioPowerQuery(),
    refetchInterval: 15000,
  });

  const filteredSites = data?.sites?.filter((site) => filteredSitesUuids.includes(site.uuid)) || [];

  useEffect(() => {
    const areaVisibility = keys.reduce((acc, key) => {
      return { ...acc, [key]: true };
    }, {});
    localStorage.setItem("areaVisibility", JSON.stringify(areaVisibility));
  }, [keys]);

  useEffect(() => {
    /*
    <site>?alarm_uuid=<alarm>.
    If the alarm is not found on the site page, then pop up the banner.
    */
    const siteUuid = searchParams.get("site");
    const alarmUuid = searchParams.get("alarm");

    if (filteredSites.length > 0) {
      const redirectSite = filteredSites.find((site) => site?.uuid === siteUuid);
      if (redirectSite) {
        const redirectUrl =
          "/site/" +
          redirectSite.name.replace(/ /g, "-") +
          (alarmUuid ? "?alarm=" + alarmUuid : "");
        navigate(redirectUrl);
      }
    }
  }, [filteredSites]);

  const getErrorBox = () => {
    return (
      <Box
        height="400px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <DataComponentError />
      </Box>
    );
  };

  const getLoadingBox = () => {
    return (
      <Box
        height="400px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  };

  return (
    <Box>
      <Box flexDirection="column" ml="80px" pt="80px" pl="4em" pr="4em">
        <Box>
          <Typography className="dashboard" variant="h6" component="div">
            Dashboard
          </Typography>
          <div className="line"></div>
        </Box>

        {error && getErrorBox()}

        {isLoading && getLoadingBox()}

        {!isLoading && !error ? (
          <Box sx={{ maxWidth: "1400px", margin: "auto" }}>
            <DashboardFilter sites={data?.sites} setFilteredSitesUuids={setFilteredSitesUuids} />
            <CollapsibleTable sites={filteredSites} error={error} isLoading={isLoading} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

Dashboard.propTypes = {
  flags: PropTypes.object,
};

export default withLDConsumer()(Dashboard);
