import { get } from "http";
import { SitePowerProduction, PowerSource } from "./Types";
import { cloneDeep } from "lodash";

export class SiteFilterOptions {
  customer: Set<string>;
  iso: Set<string>;
  state: Set<string>;
  operatingState: Set<string>;
  assets: Set<string>;

  constructor() {
    this.customer = new Set();
    this.iso = new Set();
    this.state = new Set();
    this.operatingState = new Set();
    this.assets = new Set();
  }
}

const getCustomer = (site: SitePowerProduction) => site?.customer?.name;
const getIso = (site: SitePowerProduction) => site?.iso?.name;
const getState = (site: SitePowerProduction) => site?.address?.state;
const getOperatingState = (site: SitePowerProduction) => site?.state;
const getAssets = (site: SitePowerProduction): string[] =>
  site.power_data.source.map((source: PowerSource) => source.name);

const valueGetters = {
  customer: getCustomer,
  iso: getIso,
  state: getState,
  operatingState: getOperatingState,
  assets: getAssets,
};

export const getOptions = (sites: SitePowerProduction[], field: keyof SiteFilterOptions) => {
  let options: Set<string> = new Set();

  for (const site of sites) {
    const value = valueGetters[field](site);
    if (!value) {
      continue;
    }
    // handle assets (multiple values)
    if (Array.isArray(value)) {
      value.forEach((element) => {
        options.add(element);
      });
    } else if (typeof value === "string") options.add(value);
  }

  return options;
};

export const getFilteredSites = (
  sites: SitePowerProduction[],
  filterOptions: SiteFilterOptions,
) => {
  return sites.filter((site) => {
    let filterKey: keyof SiteFilterOptions;
    for (filterKey in filterOptions) {
      const activeFieldFilter = filterOptions[filterKey];
      const siteFieldValue = valueGetters[filterKey](site);

      const emptyFilter = activeFieldFilter.size === 0;

      let valueIsInFilter = false;
      // handle assets (multiple values)
      if (Array.isArray(siteFieldValue)) {
        // effectively an OR filter
        valueIsInFilter = siteFieldValue.some((value) => activeFieldFilter.has(value));
      } else if (typeof siteFieldValue === "string") {
        valueIsInFilter = activeFieldFilter.has(siteFieldValue);
      }

      if (!emptyFilter && !valueIsInFilter) {
        // didn't pass this field filter, so don't include site
        return false;
      }
    }
    // passed all field sub filters, so include site
    return true;
  });
};

// Allows progressive filtering of sites in dashboard.
export const getFilteredOptions = (
  sites: SitePowerProduction[],
  filterOptions: SiteFilterOptions,
  excludedField: string,
) => {
  let options = cloneDeep(filterOptions);
  options[excludedField as keyof SiteFilterOptions] = new Set();
  const partiallyFilteredSites = getFilteredSites(sites, options);
  return getOptions(partiallyFilteredSites, excludedField as keyof SiteFilterOptions);
};
