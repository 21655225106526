// This file is primarely boilerplate code provided by Auth0 to handle the redirect after a successful login through the external login page.

import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { putOwnUserSettingsMutation } from "../../lib/Queries";

const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const currentTime = new Date().toISOString();
  const newUserSettings = {
    last_log_in: currentTime,
  };
  const mutation = useMutation({
    ...putOwnUserSettingsMutation(),
  });

  const onRedirectCallback = (appState) => {
    mutation.mutate(newUserSettings);
    if (appState?.returnTo.includes("login")) {
      return navigate("/");
    }
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.node,
};

export default Auth0ProviderWithNavigate;
